import React, { useState } from "react";
import { Row, Col, Card, Form, Button, InputGroup } from "react-bootstrap";
import Icon from "../../components/Icon/Icon";
import Layout from "../../layout/topNav";
import Block from "../../components/Block/Block";

import States from "../../static-data/states.json";

import { DataStore } from "@aws-amplify/datastore";
import { PrimaryContact } from "../../models";

import AddTeam from "./Add-Team";

function Register() {
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [primaryContact, setPrimaryContact] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "California",
    zip: "",
    phone: "",
    email: "",
  });

  const [teams, setTeams] = useState({});

  const onPrimaryContactInputChange = (e) => {
    setPrimaryContact((state) => ({
      ...primaryContact,
      [e.target.name]: e.target.value,
    }));
  };

  const onHideAddTeam = (data) => {
    setShowAddTeam(false);
  };

  const onAddTeam = (data) => {
    alert(JSON.stringify(data.data));
    setShowAddTeam(false);
  };

  const saveMe = async (e) => {
    e.preventDefault();
    let result = await DataStore.save(new PrimaryContact(primaryContact));
    console.log("saveme clicked: " + JSON.stringify(result));
  };

  return (
    <Layout title="Layout" content="container">
      <Block>
        <Block.Head className="wide-md">
          <Block.HeadContent>
            <Block.Title tag="h3">Form grid</Block.Title>
            <Block.Text>
              More complex forms can be built using the grid components.
            </Block.Text>
          </Block.HeadContent>
        </Block.Head>
        <Card>
          <Card.Header
            style={{
              color: "white",
              fontWeight: "bold",
              backgroundColor: "#5f38f9",
            }}
          >
            Primary Contact
          </Card.Header>
          <Card.Body>
            <Form>
              <Row className="g-4">
                <Col md="6">
                  <Form.Group controlId="inputFirstName">
                    <Form.Label>First Name</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        name="firstName"
                        type="text"
                        value={primaryContact.firstName}
                        onChange={(e) => onPrimaryContactInputChange(e)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="inputLastName">
                    <Form.Label>Last Name</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={primaryContact.lastName}
                        onChange={(e) => onPrimaryContactInputChange(e)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="inputPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        name="phone"
                        type="text"
                        value={primaryContact.phone}
                        onChange={(e) => onPrimaryContactInputChange(e)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="inputEmail">
                    <Form.Label>E-mail Address</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        name="email"
                        value={primaryContact.email}
                        onChange={(e) => onPrimaryContactInputChange(e)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col sm="12">
                  <Form.Group controlId="inputAddress">
                    <Form.Label>Address</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        name="address"
                        value={primaryContact.address}
                        onChange={(e) => onPrimaryContactInputChange(e)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="inputCity">
                    <Form.Label>City</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        name="city"
                        value={primaryContact.city}
                        onChange={(e) => onPrimaryContactInputChange(e)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group controlId="inputState">
                    <Form.Label>State</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Select
                        name="state"
                        value={primaryContact.state}
                        onChange={(e) => onPrimaryContactInputChange(e)}
                      >
                        {States.map((state) => (
                          <option value={state.name}>{state.name}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group controlId="inputZip">
                    <Form.Label>Zip</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        name="zip"
                        value={primaryContact.zip}
                        onChange={(e) => onPrimaryContactInputChange(e)}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: "15px" }}>
          <Card.Header
            style={{
              color: "white",
              fontWeight: "bold",
              backgroundColor: "#5f38f9",
            }}
          >
            Teams
          </Card.Header>
          <Card.Body>
            <div
              className="border border-1 col border-end  d-flex justify-content-center align-items-center"
              style={{ height: "240px", width: "140px", borderRadius: "10px" }}
            >
              <Button
                variant="outline-primary"
                onClick={() => setShowAddTeam(true)}
              >
                <Icon style={{ size: "lg" }} name="plus" />
              </Button>
              <AddTeam
                show={showAddTeam}
                onHide={onHideAddTeam}
                onClick={onAddTeam}
                title="Add New Team"
              />
            </div>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: "15px" }}>
          <Card.Header
            style={{
              color: "white",
              fontWeight: "bold",
              backgroundColor: "#5f38f9",
            }}
          >
            Payment
          </Card.Header>
          <Card.Body>
            <button onClick={saveMe}>Save</button>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default Register;
