import React, { useState } from "react";
import {
  Modal,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  ListGroup,
  Alert,
} from "react-bootstrap";
import Icon from "../../components/Icon/Icon";

function AddTeam(props) {
  const [team, setTeam] = useState({
    coachFirstName: "",
    coachLastName: "",
    coachEmail: "",
    coachPhone: "",
    name: "",
    division: "",
    bracket: "",
  });

  const [showPlayersRequired, setShowPlayersRequired] = useState(false);
  const [players, setPlayers] = useState([]);
  const [currentPlayer, setPlayer] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    shirtSize: "",
  });

  const onTeamInputChange = (e) => {
    setTeam((state) => ({
      ...team,
      [e.target.name]: e.target.value,
    }));
  };

  const onCurrentPlayerInputChange = (e) => {
    setPlayer((state) => ({
      ...currentPlayer,
      [e.target.name]: e.target.value,
    }));
  };

  const GetNextPlayerId = () => {
    let nextId = 1;
    if (players.length > 0) {
      nextId =
        players
          .map((a) => a.id)
          .reduce((a, b) => {
            return Math.max(a, b);
          }) + 1;
    }
    return nextId;
  };

  const HasPlayersAdded = () => {
    return players.length > 0;
  };

  const AddNewPlayer = (e) => {
    e.preventDefault();
    const updatedPlayers = [
      ...players,
      {
        id: GetNextPlayerId(),
        firstName: currentPlayer.firstName,
        lastName: currentPlayer.lastName,
        shirtSize: currentPlayer.shirtSize,
      },
    ];
    setPlayers(updatedPlayers);
    ResetCurrentPlayer();
  };

  const ResetCurrentPlayer = () => {
    setPlayer({
      id: 0,
      firstName: "",
      lastName: "",
      shirtSize: "",
    });
  };

  const DeletePlayer = (id) => {
    setPlayers((state) =>
      state.filter((player) => {
        return player.id !== id;
      })
    );
  };

  const onModalEnter = () => {
    ResetCurrentPlayer();
    setPlayers([]);
    setTeam({
      coachFirstName: "",
      coachLastName: "",
      coachEmail: "",
      coachPhone: "",
      name: "",
      division: "",
      bracket: "",
    });
  };

  return (
    <div>
      <Modal
        size="lg"
        show={props.show}
        on
        onHide={() => props.onHide({ data: {} })}
        onEnter={onModalEnter}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (HasPlayersAdded()) {
              props.onClick({ data: { team, players } });
            } else {
              setShowPlayersRequired(true);
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Card>
              <Card.Header
                style={{
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "#5f38f9",
                }}
              >
                Team
              </Card.Header>
              <Card.Body>
                <Row className="g-4">
                  <Col md="12">
                    <Form.Group controlId="inputTeamName">
                      <Form.Label>Team Name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          name="name"
                          type="text"
                          value={team.name}
                          onChange={(e) => onTeamInputChange(e)}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm="6">
                    <Form.Group controlId="inputDivision">
                      <Form.Label>Division</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          name="division"
                          value={team.division}
                          onChange={(e) => onTeamInputChange(e)}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="inputBracket">
                      <Form.Label>Bracket</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          name="bracket"
                          value={team.bracket}
                          onChange={(e) => onTeamInputChange(e)}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "15px" }}>
              <Card.Header
                style={{
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "#5f38f9",
                }}
              >
                Coach
              </Card.Header>
              <Card.Body>
                <Row className="g-4">
                  <Col md="6">
                    <Form.Group controlId="inputFirstName">
                      <Form.Label>First Name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          name="coachFirstName"
                          type="text"
                          value={team.coachFirstName}
                          onChange={(e) => onTeamInputChange(e)}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="inputLastName">
                      <Form.Label>Last Name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          name="coachLastName"
                          value={team.coachLastName}
                          onChange={(e) => onTeamInputChange(e)}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="inputPhone">
                      <Form.Label>Phone Number</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          name="coachPhone"
                          value={team.coachPhone}
                          onChange={(e) => onTeamInputChange(e)}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="inputEmail">
                      <Form.Label>E-mail Address</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          name="coachEmail"
                          value={team.coachEmail}
                          onChange={(e) => onTeamInputChange(e)}
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "15px" }}>
              <Card.Header
                className="d-flex justify-content-between align-items-center"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "#5f38f9",
                }}
              >
                Players
              </Card.Header>
              <Card.Body>
                {showPlayersRequired && (
                  <div>
                    <Alert className="d-flex">
                      <Icon name="alert-fill" className="me-1 mt-1"></Icon>
                      <div>Players are required for each team.</div>
                    </Alert>
                  </div>
                )}
                <Form onSubmit={AddNewPlayer}>
                  <Row className="g-4">
                    <Col md="4">
                      <Form.Group controlId="inputFirstName">
                        <Form.Label>First Name</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="firstName"
                            type="text"
                            value={currentPlayer.firstName}
                            onChange={onCurrentPlayerInputChange}
                            required
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group controlId="inputLastName">
                        <Form.Label>Last Name</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="lastName"
                            type="text"
                            value={currentPlayer.lastName}
                            onChange={onCurrentPlayerInputChange}
                            required
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm="2">
                      <Form.Group controlId="inputShirtSize">
                        <Form.Label>Shirt Size</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="shirtSize"
                            type="text"
                            value={currentPlayer.shirtSize}
                            onChange={onCurrentPlayerInputChange}
                            required
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="d-flex" md="2">
                      <Button variant="outline-primary" type="submit">
                        Add Player
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {players.length > 0 && (
                  <div>
                    <hr />
                    <Card style={{ marginTop: "5px" }}>
                      <ListGroup variant="flush">
                        {players.length > 0 &&
                          players.map((player) => (
                            <ListGroup.Item>
                              <div className="d-flex justify-content-between align-items-center">
                                {player.firstName} {player.lastName}{" "}
                                <div>
                                  <Icon name="user-alt" />
                                  {player.shirtSize}
                                </div>
                                <div>
                                  <Button
                                    onClick={() => DeletePlayer(player.id)}
                                    variant="outline-primary"
                                  >
                                    <Icon name="trash" />
                                  </Button>
                                </div>
                              </div>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    </Card>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" type="submit">
              Add Team
            </Button>
            <Button
              variant="secondary"
              onClick={() => props.onClick({ data: {} })}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default AddTeam;
