import React, { useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Layout from "../../layout/fullpage";

import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Logo,
} from "../../components";
import { Auth } from "aws-amplify";
import FormControlPage from "../forms/FormControl";

const AuthLoginPage = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const signIn = (event) => {
    event.preventDefault();
    Auth.signIn(username, password)
      .then((user) => {
        console.log(JSON.stringify(user));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Layout title="Login" centered>
        <div className="container p-2 p-sm-4">
          <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
            <Row className="g-0 flex-lg-row-reverse">
              <Col lg="5">
                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                  <div className="nk-block-head text-center">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title mb-1">Login to Account</h3>
                      <p className="small">
                        Please sign-in to your account and start the adventure.
                      </p>
                    </div>
                  </div>
                  <Form action="#">
                    <Row className="gy-3">
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="email">Email</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="text"
                              id="email"
                              placeholder="Enter email"
                              onChange={(e) => {
                                setUserName(e.target.value);
                              }}
                              required
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="password">Password</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="password"
                              id="password"
                              placeholder="Enter password"
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              required
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <div className="d-flex flex-wrap justify-content-between">
                          <Form.Check
                            className="form-check-sm"
                            type="checkbox"
                            id="rememberMe"
                            label="Remember Me"
                          />
                          <Link to="/auths/auth-reset" className="small">
                            Forgot Password?
                          </Link>
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="d-grid">
                          <Button type="submit" onClick={signIn}>
                            Login to account
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div className="text-center mt-4">
                    <p className="small">
                      Don't have an account?{" "}
                      <Link to="/auths/auth-register">Register</Link>
                    </p>
                  </div>
                </Card.Body>
              </Col>
              <Col lg="7">
                <Card.Body className="bg-darker is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-end">
                  <div className="mask mask-1"></div>
                  <div className="row">
                    <div className="col-sm-11">
                      <div className="mt-4">
                        <div className="h1 title mb-3">It's Game Time!</div>
                        <p>
                          Login to manage tournament resources, teams, game
                          schedules, and team registrations.
                        </p>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </Layout>
    </>
  );
};

export default AuthLoginPage;
