import React, { useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Layout from "../../layout/fullpage";
import { Auth } from "aws-amplify";

import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Logo,
} from "../../components";

function AuthResetPage() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const resetPassword = (event) => {
    event.preventDefault();
    try {
      const { user } = Auth.resetPassword({
        username,
        code,
        password,
      });
      console.log(user);
    } catch (error) {
      console.log("error resetting password:", error);
    }
  };

  return (
    <Layout title="Reset Password" centered>
      <div className="container p-2 p-sm-4">
        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
          <Row className="g-0 flex-lg-row-reverse">
            <Col lg="5">
              <Card.Body className="h-100 d-flex flex-column justify-content-center">
                <div className="nk-block-head text-center">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title mb-1">Reset Password</h3>
                  </div>
                </div>
                <Form action="#">
                  <Row className="gy-3">
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="email"
                            id="email"
                            placeholder="Enter email address"
                            onChange={(e) => {
                              setUserName(e.target.value);
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="name">Reset Code</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="code"
                            placeholder="Enter Reset Code"
                            onChange={(e) => {
                              setCode(e.target.value);
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">Password</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="password"
                            placeholder="Enter password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="col-12">
                      <div className="d-grid">
                        <Button type="submit" onClick={resetPassword}>
                          Reset Password
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Col>
            <Col lg="7">
              <Card.Body className="bg-darker is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-end">
                <div className="mask mask-1"></div>
                <div className="row">
                  <div className="col-sm-11">
                    <div className="mt-4">
                      <div className="h1 title mb-3">It's Game Time!</div>
                      <p>Reset account password.</p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>
    </Layout>
  );
}

export default AuthResetPage;
