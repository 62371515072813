import React, { useState } from "react";
import { Row, Col, Card, Form, Button, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import Layout from "../../layout/fullpage";
import { Auth } from "aws-amplify";

import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Logo,
} from "../../components";

function AuthConfirm() {
  const [username, setUserName] = useState("");
  const [code, setCode] = useState("");

  const confirmSignUp = (event) => {
    event.preventDefault();
    try {
      const { user } = Auth.confirmSignUp({
        username,
        code,
      });
      console.log(user);
    } catch (error) {
      console.log("error sign up confirmation:", error);
    }
  };

  const resendConfirmationCode = (event) => {
    try {
      Auth.resendSignUp(username);
      console.log("code resent successfully");
    } catch (err) {
      console.log("error resending code: ", err);
    }
  };

  return (
    <Layout title="Register" centered>
      <div className="container p-2 p-sm-4">
        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
          <Row className="g-0 flex-lg-row-reverse">
            <Col lg="5">
              <Card.Body className="h-100 d-flex flex-column justify-content-center">
                <div className="nk-block-head text-center">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title mb-1">Confirm Sign Up</h3>
                  </div>
                  <div className="text-center mt-4">
                    <p className="small">
                      A confirmation code was sent to the email address used
                      during sign up.
                    </p>
                  </div>
                </div>
                <Form action="#">
                  <Row className="gy-3">
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <div className="form-control-wrap">
                          <InputGroup>
                          <Form.Control
                            type="email"
                            id="email"
                            placeholder="Enter email address"
                            onChange={(e) => {
                              setUserName(e.target.value);
                            }}
                          />
                          <InputGroup.Text id="basic-addon1"><Link onClick={resendConfirmationCode}>Resend Code</Link></InputGroup.Text>
                          </InputGroup>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="code">
                          Confirmation Code
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            placeholder="Enter Code"
                            onChange={(e) => {
                              setCode(e.target.value);
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <div className="d-grid">
                        <Button type="submit" onClick={confirmSignUp}>
                          Confirm Sign Up
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Col>
            <Col lg="7">
              <Card.Body className="bg-darker is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-end">
                <div className="mask mask-1"></div>
                <div className="row">
                  <div className="col-sm-11">
                    <div className="mt-4">
                      <div className="h1 title mb-3">It's Game Time!</div>
                      <p>Create a new account to register your team.</p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>
    </Layout>
  );
}

export default AuthConfirm;
