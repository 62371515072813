// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Player, Team, PrimaryContact } = initSchema(schema);

export {
  Player,
  Team,
  PrimaryContact
};